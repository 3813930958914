body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /*  to not let the content shift when srollbar appears. REFER: https://www.reddit.com/r/webdev/comments/svfsl3/comment/hxgmhft/ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* this is included to force show scrollbar in macOS chrome/safari */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c4c4c4;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* needed for sidebar animation jank:  https://css-tricks.com/stop-animations-during-window-resizing/ */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

/* this to put button in active style when row is being hovered */
.showOnHover button:enabled:first-child {
  background-color: #e6f1fd;
}
